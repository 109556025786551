<p-sidebar
    *ngIf="dialogType === 'sideBar'"
    [(visible)]="sidebarVisible"
    class="document-sidebar"
    position="right"
    role="region"
    (onHide)="onCloseSidebar()"
    [styleClass]="documentModel?.dataDictionary || deltaTable?.dataDictionary || (documentModel?.documentRevisions && header==='Document Revision') ? 'w-40rem' : 'w-30rem'"
>
    <ng-template pTemplate="content">
        <h2 *ngIf="header" class="mb-1">{{ header }}</h2>
        <h4 class="mb-3"> {{ documentName }} </h4>
        <div [markdown]="true">{{summaryData}} </div>
      </ng-template>
</p-sidebar>

<div *ngIf="dialogType === 'documentSummary'">
    <p-dialog
        [header]="header"
        [(visible)]="sidebarVisible"
        [modal]="true"
        (onHide)="onCloseSidebar()"
        [style]="{ width: '80vw' }"
        [draggable]="false"
        [resizable]="false"
    >
        <ng-template pTemplate="header">
            <div
                class="flex justify-content-between align-items-center headerContainer"
            >
                <h3 class="px-5">{{ header }} - {{ documentName }}</h3>
                <div>
                    <span
                        class="mr-3 cursor"
                        (click)="copyText()"
                        *ngIf="!iscopyingSummary"
                        ><i class="bi bi-clipboard mr-1"></i>
                    </span>
                    <span class="mr-3" *ngIf="iscopyingSummary"
                        ><i class="bi bi-check2 mr-1"></i>
                    </span>
                    <span class="mr-3 cursor" *ngIf="!downloadSummary" (click)="downloadPdfFile()"
                        ><i class="bi bi-download mr-1"></i>
                    </span>
                     <span class="mr-3" *ngIf="downloadSummary"
                        ><i  class="pi pi-spin pi-spinner mr-1  "> </i>
                    </span>

                </div>
            </div>
        </ng-template>
        <!-- <div class="px-4" #documentText  [innerText]="summaryData">

    </div> -->
        <div #documentText>
            <div *ngFor="let summary of summaryDataSplit; let i = index">
                <span *ngIf="i !== 0"><br /></span>
                <div
                    *ngFor="let text of summary.split('\n\n'); let idx = index"
                >
                    <span *ngIf="idx !== 0"><br /></span>
                    <div
                        class="px-5"
                        [ngClass]="
                            idx === 0 &&
                            summary.split('\n\n').length > 1 &&
                            longSummaryType === summaryTypeCIM
                                ? 'headers'
                                : 'content'
                        "
                        [innerText]="text"
                    ></div>
                </div>
            </div>
        </div>
    </p-dialog>
</div>
