import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges,ViewChild, ElementRef
} from '@angular/core';
import { SummaryType } from '@common/enums/SummaryType';
import { IDeltaTable } from '@common/models/Document/IDeltaTable';
import { IDocument } from '@common/models/Document/IDocument';
import { ITableRow } from '@common/models/Document/ITableRow';
import jsPDF from 'jspdf';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.scss'],
  providers: [DialogService]
})
export class SummaryDialogComponent implements OnInit,OnChanges {
  @ViewChild('documentText', { static: false }) documentText: ElementRef;
  ref: DynamicDialogRef | undefined;
  @Input() sidebarVisible: boolean;
  @Input() summaryData: string;
  @Input() documentModel: IDocument;
  @Input() longSummaryType: string;
  @Input() header:string;
  @Input() dialogType:string;
  @Input() documentName:string;
  @Input() deltaTable:IDeltaTable;
  iscopyingSummary:boolean=false;
  downloadSummary:boolean=false;
  summaryTypeCIM:SummaryType=SummaryType.CIM
  @Output() emitSidebarUpdate: EventEmitter<boolean> =
    new EventEmitter();
  summaryDataSplit:string[];
  excelType = [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel']

  constructor(public dialogService: DialogService) { }

  ngOnInit(): void {

  }

  convertJsonToMarkdown(documentModel: IDocument): string {
    let data = documentModel.dataDictionary
    const tableNames = Object.keys(data);
    let markdown = '';
    tableNames.forEach((table) => {
      if(data[table][0].trim() !== ''){
      const tableData = data[table][0];
      const parsedData = JSON.parse(tableData.replace(/```json\n|\n```/g, ''));
      const rows = Object.entries(parsedData);
      if(this.excelType.includes(documentModel?.contentType)){
        markdown += `#### ${table}\n`;
      }
      markdown += `| Column | Description |\n`;
      markdown += `| --- | ----- |\n`;
      rows.forEach(([key, value]) => {
        markdown += `| ${key} | ${value} |\n`;
      });
    }
      markdown += '\n';
    });

    return markdown;
  }

  DeltaTableDictToMarkdown(data: ITableRow[],tableName:string): string {
    let markdownTable = '';
      markdownTable += `#### ${tableName}\n`;
      markdownTable = '| Column | DataType | Description |\n';
      markdownTable += '|--------|----------|-------------|\n';

      for (const item of data) {
          markdownTable += `| ${item.col_name} | ${item.data_type} | ${item.comment} |\n`;
      }

    return markdownTable;
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['summaryData']) {
      this.summaryDataSplit = this.longSummaryType==SummaryType.CIM? this.summaryData.split(/\n{4,}/) : [this.summaryData]
    }
    if (changes['documentModel'] || changes['sidebarVisible'].currentValue === true) {
      if(this.documentModel?.dataDictionary){
        this.summaryData = this.convertJsonToMarkdown(this.documentModel)
      }
    }
    if (changes['deltaTable'] || changes['sidebarVisible'].currentValue === true) {
      if(this.deltaTable?.dataDictionary){
        this.summaryData = this.DeltaTableDictToMarkdown(this.deltaTable?.dataDictionary,this.deltaTable?.tableName)
      }
    }
}

  onCloseSidebar(){
    this.emitSidebarUpdate.emit(false)
  }

  copyText() {
    this.iscopyingSummary=true
    setTimeout(() => {
      this.iscopyingSummary=false;
  }, 1000);
    const textToCopy = this.documentText.nativeElement.innerText;

    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  downloadPdfFile() {
    this.downloadSummary = true;
    const doc = new jsPDF('p', 'mm', 'a4');
    const anchor = this.documentText.nativeElement.innerText;
    const fontSize = 11;
    let currentY = 20;
    const lineHeight = 6;

    if (anchor) {
        doc.setFont('IBM Plex Sans', 'bold')
        doc.setFontSize(14)
        doc.text(this.documentName,10 ,currentY);
        doc.setFontSize(fontSize);
        currentY = currentY+10
        const textlines = this.longSummaryType==SummaryType.CIM? this.summaryData.split(/\n{4,}/) : [this.summaryData]
        textlines.forEach(section => {
          section.split('\n\n').forEach((line,index)=>{
          if (index == 0 && section.split('\n\n').length >0 && this.longSummaryType === this.summaryTypeCIM ) {
            currentY = currentY +10
            doc.setFont('IBM Plex Sans', 'bold');
          } else {
            currentY = currentY +2
            doc.setFont('IBM Plex Sans', 'normal');

          }
          const splitLines =  doc.splitTextToSize(line, 180);
          splitLines.forEach(subLine => {
              doc.text(subLine, 10, currentY);
              currentY += lineHeight;
              if (currentY > doc.internal.pageSize.getHeight() - 20) {
                  doc.addPage();
                  currentY = 20;
              }
          });
          })
          })

        doc.save(this.documentName);
    }

    setTimeout(() => {
        this.downloadSummary = false;
    }, 1000);
}


}
