<p-dialog header="Select tables" class="header" [(visible)]="display" [modal]="true" [draggable]="false" [style]="{width: '40vw'}" (onHide)="onCancel()">
  <div *ngIf="tableNames[CSVFiles[0]?.documentName]?.length===0 " class="loading flex justify-content-center align-items-center">
    <app-loader [isLoading]="tableNames[CSVFiles[0]?.documentName]?.length===0 "></app-loader>
</div>
  <div *ngIf="CSVFiles?.length>0">
  <div class="p-grid headers mb-2">
    <div class="p-col-1"></div>
    <div class="p-col-4">File</div>
    <div class="p-col-3">Sheets</div>
    <div class="p-col-4">Tables</div>
  </div>
  <div *ngFor="let csv of CSVFiles">
  <div *ngFor="let file of tableNames[csv.documentName]; let i = index" class="p-grid file-details">
    <div class="p-col-1 flex">
        <!-- <p-radioButton name="selectedFile" [(ngModel)]="selectedFile" [value]="file"></p-radioButton> -->
        <input type="checkbox" class="cursor label"  name="selectedFile" [(ngModel)]="file.selected" [value]="file" />
    </div>
    <div class="p-col-4 filename-cell pl-0">
      <span class="filename" pTooltip="{{file.documentName}}" tooltipPosition="top">{{ file.fileName }}</span>
    </div>
    <div class="p-col-3">
      <p-multiSelect appendTo="body" [options]="file.sheets" [(ngModel)]="file.selectedSheets" optionLabel="sheetName" (onChange)="updateSheetTables(file)" multiple></p-multiSelect>

      <!-- <span class="filename" pTooltip="{{file.sheet}}" tooltipPosition="top">{{ file.sheet }}</span> -->
    </div>
    <div class="p-col-4">
      <!-- <p-inputNumber [(ngModel)]="file.columns" class="input" [min]="0" inputId="rows{{i}}"></p-inputNumber> -->
      <!-- <input type="text" pInputText [(ngModel)]="file.columns" /> -->
       <p-multiSelect appendTo="body" [options]="file.sheetTables" [disabled]="!file.sheetTables || file.sheetTables?.length === 0" [(ngModel)]="file.selectedTables" optionLabel="tableName"  multiple></p-multiSelect>
    </div>
  </div>
  </div>
</div>
    <p-footer>
        <button type="button" [disabled]="checkValidity()" class="p-button p-button-primary" (click)="onConfirm()">Submit</button>
    </p-footer>
  </p-dialog>
