/**
 * to represent either document is processed or not
 */
export enum DocumentStatus {
  Processing = "processing",
  Converting = "converting",
  Processed = "processed",
  Failed = "failed",
  Deleted = 'deleted',
  Archived = 'archived'
}
