import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SummaryType } from '@common/enums/SummaryType';
import { IDocument } from '@common/models/Document/IDocument';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
  providers: [RadioButtonModule]
})
export class SelectDialogComponent implements OnInit {
  selectedSummaryType: SummaryType=SummaryType.CIM
  constructor(private confirmationService: ConfirmationService, private messageService: MessageService) { }
  @Input() showdialog: boolean
  @Input() selectedDocForSummary: IDocument
  @Output() emitSummaryForDoc: EventEmitter<SummaryType> = new EventEmitter()
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter()

  ngOnInit(): void {
      
  }
  emitSummary(){
    this.emitSummaryForDoc.emit(this.selectedSummaryType)
  }
  onClose(){
    this.closeDialog.emit(false)
  }
  
}
