import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  webSocketConnection: boolean = false;
  private socket: Socket;

  constructor() {
    this.openSocket();
  }

  openSocket(): void {
    let socketHost = environment.baseURL.replace(/^https/, 'wss');
    if (environment.envName === 'local') {
      socketHost = 'ws://localhost:8080';
    }

    // console.log('check url', socketHost);

    this.socket = io(socketHost, {
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      console.log('Socket connected');
    });

    this.socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    this.socket.on('ping', () => {
      console.log('Received ping from server, sending pong');
      this.socket.emit('pong');
    });
  }

  joinRoom(roomId: string): void {
    this.socket.emit('joinRoom', roomId);
  }

  getMessages(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('message', (data: any) => {
        observer.next(data);
      });

      this.socket.on('error', (error: any) => {
        console.error('Socket error', error);
        observer.error(error);
      });

      this.socket.on('close', () => {
        console.log('Socket Connection closed');
        observer.complete();
      });
    });
  }

  sendMessage(message: any): void {
    if (this.socket.connected) {
      this.socket.emit('message', message);
    } else {
      console.error('Socket is not connected. Message not sent.');
    }
  }

  closeConnection(): void {
    this.socket.disconnect();
  }

  isWebSocketConnected(): boolean {
    return this.socket.connected;
  }

  waitForConnection(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.socket.connected) {
        resolve();
      } else {
        const interval = setInterval(() => {
          if (this.socket.connected) {
            clearInterval(interval);
            resolve();
          }
        }, 500);
      }
    });
  }

  checkConnectionWithPingPong(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const timeout = setTimeout(() => {
        resolve(false);
      }, 5000);
      // Listen for the 'pong' response
      this.socket.once('pong', () => {
        clearTimeout(timeout);
        resolve(true);
      });
      // Send the 'ping' event
      this.socket.emit('ping');
    });
  }
}
