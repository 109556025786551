import { Directive, Input, OnInit } from '@angular/core';

import { BaseToggleDirective } from './base-toggle.directive';

@Directive({
    selector: '[availableFor]'
})
export class AvailableForDirective extends BaseToggleDirective implements OnInit {

  private readonly  internalRoles: string[] = ['GlobalAdmin','User'];
  private readonly targetRoles: string[] = ['User','External'];


  @Input() isAdminPage: boolean;
  @Input() isallUsersPage: boolean;


  protected isElementNeeded(): boolean {
    if (this.isAdminPage) {
      return this.currentUserStateService.hasRole('GlobalAdmin');
    }

    if(this.isallUsersPage){
       return this.currentUserStateService.hasOneOfRoles(this.targetRoles);
    }

  return this.currentUserStateService.hasOneOfRoles(this.internalRoles);
  }
}
