import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SummaryType } from '@common/enums/SummaryType';
import { IChatInteraction } from '@common/models/Chat/IChatInteraction';
import { IDocument } from '@common/models/Document/IDocument';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {
  value:string
  selectedSummaryType: SummaryType
  constructor(private confirmationService: ConfirmationService, private messageService: MessageService) { }
  @Input() showdialog: boolean
  @Input() selectedChatInteraction: IChatInteraction
  @Input() like:boolean;
  @Output() emitSummaryForDoc: EventEmitter<string> = new EventEmitter()
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter()
  ngOnInit(): void {
  }

  emitSummary(){
    this.emitSummaryForDoc.emit(this.value)
  }
  onClose(){
    this.closeDialog.emit(false)
  }

}
