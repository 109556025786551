<p-dialog header="SQL code to get the result" [(visible)]="showdialog" [modal]="true" [style]="{width: '40vw'}" [draggable]="false" (onHide)="onClose()">
    <div class="code-block-header">
        <p class="copy-button px-2" (click)="copyCodeToClipboard()">
            <i *ngIf="!showCopied" class="bi bi-clipboard mr-1"></i>
            <i *ngIf="showCopied" class="bi bi-check2 mr-1"></i>
            <span class="exclude-me">{{ showCopied ? 'Copied' : 'Copy Code' }}</span>
        </p>
    </div>

    <div class="code-div"><pre *ngIf="selectedInteractionCodeText"><code>{{ selectedInteractionCodeText }}</code></pre></div>
</p-dialog>
