import { Injectable } from '@angular/core';
import { IUser } from '@common/models/IUser';
import { BaseStateService } from './baseState.service';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserStateService extends BaseStateService<IUser> {
    public hasRole(role: string): boolean {
        const roles = this.value?.roles || [];
        return roles.includes(role)
    }

    public hasOneOfRoles(roles: string[]): boolean {
        const userRoles = this.value?.roles;

        for (const role of roles) {
            if (userRoles?.includes(role)) {
                return true;
            }
        }

        return false;
    }
}
