import { Component, OnInit ,Input} from '@angular/core';
import { IChatResponse } from '@common/models/Chat/IChatResponse';
import { ITableRow } from '@common/models/Document/ITableRow';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @Input() chatResp: IChatResponse;
  constructor() { }

  ngOnInit(): void {
    if(this.chatResp.chart){
      let options : ITableRow = {
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: 'slider',
            height: 15,
            xAxisIndex: 0,
            start: 0,
            end: 100,
          },
          {
            type: 'slider',
            width: 15,
            yAxisIndex: 0,
            start: 0,
            end: 100,
            left: '93%',
            bottom:'24%'
          }
        ],
        toolbox:{
          padding: 15,
          itemSize: 13,
          feature: {
            saveAsImage: {
              show : true
            }
          }
        }
      }
      this.chatResp.chart = {...this.chatResp.chart,...options}
    }

  }

}
