import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ReleasenotesComponent } from 'quangular';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'chat',
        pathMatch: 'full',
    },
    {
        path: 'chat',
        loadChildren: () =>
            import('./modules/chat/chat.module').then((m) => m.ChatModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'datasets',
        loadChildren: () =>
            import('./modules/documents/documents.module').then(
                (m) => m.DocumentsModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'releasenotes',
        component: ReleasenotesComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
