import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DocumentService } from '@app/services/document.service';
import { DocumentStatus } from '@common/enums/DocumentStatus';
import { IChatDocument } from '@common/models/Chat/IChatDocument';
import { IChatMetaData } from '@common/models/Chat/IChatMetaData';
import { IDocument } from '@common/models/Document/IDocument';
import { meta } from 'ngx-bootstrap-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fileupload-metadata-dialog',
  templateUrl: './fileupload-metadata-dialog.component.html',
  styleUrls: ['./fileupload-metadata-dialog.component.scss']
})
export class FileuploadMetadataDialogComponent implements OnChanges {

  @Input() message: string = '';
  @Input() display: boolean = false;
  @Input() CSVFiles: IDocument[]=[];
  @Output() accept: EventEmitter<IChatDocument[]> = new EventEmitter<IChatDocument[]>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Input() type: string;
  docTableSub: Subscription = new Subscription();
  @Input() tableNames: any = {};
  selectedFile:any;

  constructor(
    private documentService: DocumentService,
) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['display']?.currentValue===true){

      this.CSVFiles.map((e:any)=>{
        let doc = e

        if(this.tableNames[doc.documentName]?.length>0){
          let tempTablesData={
            doc:this.tableNames[doc.documentName][0].doc,
            tables:[],
            sheetTables:[],
            selectedSheets:[],
            sheets:[],
            selected:true,
            fileName:this.tableNames[doc.documentName][0].fileName
          }
          this.tableNames[doc.documentName].map((e:any,i:number)=>{
            tempTablesData.sheets.push({sheetName:e.sheet})
            e.tables.map((tableNameItem:any)=>{
              tempTablesData.tables.push({
                tableName:tableNameItem,
                sheetName:e.sheet
              })
            })
            tempTablesData.sheetTables = tempTablesData.tables
            tempTablesData.selectedSheets = tempTablesData.sheets
          })
          this.tableNames[doc.documentName] = [tempTablesData]
        }
      })
    }
  }

  checkValidity(){
    let isvalid = false
    this.CSVFiles.map((csv:IDocument)=>{
      this.tableNames[csv.documentName]?.map((table:any)=>{
        if(table.selected == true && table?.selectedTables?.length > 0){
          isvalid = true;
        }
        else if(table.selected == true && (table?.selectedTables?.length == 0 || !table?.selectedTables)){
          isvalid = false;
        }
      })
    })
    return !isvalid
  }

  updateSheetTables(data:any){
    data.sheetTables = []
    data.selectedTables = []
    if(data.selectedSheets.length>0){
      data.selectedSheets.map((e:any)=>{
        data.tables.map((table:any)=>{
          if(e.sheetName == table.sheetName){
            data.sheetTables.push(table)
          }
        })
      })
    }

  }

  removeFile(index: number): void {
    // Remove the file from the CSVFiles array
    this.CSVFiles.splice(index, 1);
  }

  onConfirm() {
    const chatDoc:IChatDocument[]=[]
      this.CSVFiles.map((csv:IDocument)=>{
        if(this.tableNames[csv.documentName]){
        this.tableNames[csv.documentName].map((e:any)=>{
          if(e.selected && e.selectedTables){
            const metaData:IChatMetaData[]=[]
            e.selectedTables.map((table:any,i:number)=>{
              metaData[i]={}
              metaData[i].sheetName= table.sheetName,
              metaData[i].tableName = table.tableName
            })
            chatDoc.push({
              documentId:e.doc.documentId,
              documentName:e.fileName,
              metaData: metaData,
              structuredDocumentStatus: DocumentStatus.Processing,
              contentType: e.doc.contentType
            })
          }
        })}else{
          chatDoc.push({
            documentId:csv.documentId,
            documentName:csv.documentName,
            // structuredDocumentStatus: (csv.structureDocShortSummary && csv.structureDocShortSummary[csv.documentId]) ? DocumentStatus.Processed : DocumentStatus.Processing,
            contentType: csv.contentType
          })
        }
      })
    this.accept.emit(chatDoc);
    this.display = false;
  }

  onCancel() {
    this.display = false;
    this.cancel.emit();
  }
}


