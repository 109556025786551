import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatServices } from '@app/services/chat.service';
import { WebSocketService } from '@app/services/webSocket.service';
import { QUANGULAR_CONFIG, QuangularConfig } from 'quangular';
import { CurrentUserStateService } from '@app/services/currentUserState.service';
import { IUser } from '@common/models/IUser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
    title = 'Quantum IQ';
    getUserSub: Subscription = new Subscription();

    constructor(private chatService: ChatServices, private webSocketService: WebSocketService,@Inject(QUANGULAR_CONFIG) private config: QuangularConfig,private readonly currentUserStateService: CurrentUserStateService) { }
    ngOnInit(): void {
        this.getUserSub.add(
            this.chatService.UserMe().subscribe({
                next: async (response: IUser) => {
                    this.currentUserStateService.value = response
                    await this.webSocketService.waitForConnection()
                    this.webSocketService.joinRoom(response['id'])
                },
            })
        )
    }
    public environment(): any {
      return this.config.environment;
  }
}
