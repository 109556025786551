import {    Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  SimpleChanges} from '@angular/core';


@Component({
  selector: 'app-excel-tables-info',
  templateUrl: './excel-tables-info.component.html',
  styleUrls: ['./excel-tables-info.component.scss']
})
export class ExcelTablesInfoComponent implements OnChanges {
  @Input() display: boolean = false;
  currentScreen: number = 1;
  screens: number[] = [1, 2, 3];
  dialogWidth: string = '30%';
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }
  showCarousel = false;
  carouselItems: any[] = [
    { src: 'assets/images/excelTableCreation.png', description: `
    <ol>
    <li><p>Open the sheet of the excel you want to chat with.</p></li>
    <li><p>Click on Table in Insert tab.</p></li>
  </ol>` },
    { src:'assets/images/excelTableSelection.png', description:
    `
    <ol>
    <li><p>Select the columns and rows to be considered.</p></li>
    <li><p>Click OK.</p></li>
     </ol>`},
    { src:'assets/images/excelTableSample.png', description: `
    <ol>
      <li><p>Once the table is created, the rows and columns will be highlighted as this sample shows. The color scheme may vary.</p></li>
      <li><p>All the sheets to be considered for Chat, need to be converted to table before to QIQ.</p></li>
      <li><p>Save the file and upload it on QIQ to extract insights !!</p></li>
    </ol>

` },
  ];

  next(): void {
    if (this.currentScreen < this.screens.length) {
      this.currentScreen++;
    }
  }


  prev(): void {
    if (this.currentScreen > 1) {
      this.currentScreen--;
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['display']?.currentValue===true){
      this.showCarousel = false
    }
  }

  onConfirm() {
    this.display = false;
  }


  onCancel() {
    this.display = false;
    this.cancel.emit();
  }


}



