import {
    Component,
    OnInit,
    Input,
    OnChanges,
    EventEmitter,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    providers: [ConfirmationService],
})
export class ConfirmationDialogComponent implements OnChanges {
    @Input() message: string = '';
    constructor(private confirmationService: ConfirmationService) {}
    @Input() display: boolean = false;
    @Output() accept: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Input() type:string;
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['display']?.currentValue === true) {
            this.confirm();
        }
    }

    confirm() {
        this.confirmationService.confirm({
            message: this.message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.onConfirm();
            },
            reject: () => {
                this.onCancel();
            },
        });
    }

    onConfirm() {
        this.accept.emit();
        this.display = false;
    }

    onCancel() {
        
        this.display = false;
        this.cancel.emit();
    }
}
