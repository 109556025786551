<p-dialog header="Provide additional feedback" class="header" [(visible)]="showdialog" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false"
    [resizable]="false" (onHide)="onClose()">
    
<textarea rows="5" cols="30" pInputTextarea placeholder="{{like?'What do you like about the response?':'What was the issue with the response? How could it be improved?'}}" [(ngModel)]="value"></textarea>

<div class="button-container">
    <button label="Cancel" (click)="onClose()" class="p-button p-button-outlined-secondary mx-2">Cancel</button>
    <button label="OK" (click)="emitSummary()" class="p-button p-button-primary">Submit Feedback</button>
</div>
</p-dialog>
