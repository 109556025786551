<p-dialog  *ngIf="!showCarousel" header="Tables in Excel" [(visible)]="display" [style]="{width:'30%' }" [modal]="true" [draggable]="false" (onHide)="onCancel()">
  <div class="mx-2">
      <p class="label">Looks like NO Tables are defined within the Excel.</p>
        <p class="content mt-2">QIQ needs Tables to be defined within the Excel to chat with.<br>
        Please define Tables on all the sheets to be interacted with.<br>
        Please Click on Get Started  to know how Table selection can
        be done</p>
      <div class="flex justify justify-content-end align-items-center mt-5">
      <button label="Skip" (click)="onCancel()" class="p-button p-button-label mx-2">Skip</button>
      <button label="Info" (click)="showCarousel = true; dialogWidth =  '50%'" class="p-button p-button-primary">Get Started</button>
    </div>
  </div>
</p-dialog>
<p-dialog *ngIf="showCarousel" header="Create Tables in Excel" [(visible)]="display" [style]="{width:'60%' }" [modal]="true" [draggable]="false" (onHide)="onCancel()">
  <p-carousel [value]="carouselItems" [circular]="false" [numVisible]="1"  [numScroll]="1">
    <ng-template let-item pTemplate="item">
      <div class="carousel-content mb-3 mx-2 ">
        <img [src]="item.src" alt="Carousel image" style="max-width: 100%; display: block; margin: 0 auto;">
        <p [innerHTML]="item.description"></p>
      </div>
    </ng-template>
  </p-carousel>
</p-dialog>
