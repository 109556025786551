<p-dialog header="Please select document type" class="header" [(visible)]="showdialog" [modal]="true" [style]="{ width: '30vw' }" [draggable]="false"
    [resizable]="false" (onHide)="onClose()">
<div class="radio-item">
    <p-radioButton name="summaryType" [(ngModel)]="selectedSummaryType" value="cim"></p-radioButton>
    <label for="cim">CIM</label>
</div>
<div class="radio-item">
    <p-radioButton name="summaryType" [(ngModel)]="selectedSummaryType" value="vanilla"></p-radioButton>
    <label for="vanilla">Others</label>
</div>
<div class="button-container">
    <button label="Cancel" (click)="onClose()" class="p-button p-button-outlined-secondary cursor">Cancel</button>
    <button label="OK" (click)="emitSummary()" class="p-button p-button-primary">Process</button>
</div>
</p-dialog>
