import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { SummaryDialogComponent } from './summary-dialog/summary-dialog.component';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { StyleClassModule } from 'primeng/styleclass';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileuploadMetadataDialogComponent } from './fileupload-metadata-dialog/fileupload-metadata-dialog.component';
import { CodeDialogComponent } from './code-dialog/code-dialog.component';
import { ExcelTablesInfoComponent } from './excel-tables-info/excel-tables-info.component';
import { CarouselModule } from 'primeng/carousel';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { AvailableForDirective } from '@app/directives/available-for.directive';
import { ChartsComponent } from '@app/modules/chat/pages/chat/components/chat-main/charts/charts.component';
import { NgxEchartsModule } from 'ngx-echarts';


@NgModule({
    declarations: [
        ConfirmationDialogComponent,
        SummaryDialogComponent,
        SelectDialogComponent,
        FeedbackDialogComponent,
        LoaderComponent,
        FileuploadMetadataDialogComponent,
        CodeDialogComponent,
        ExcelTablesInfoComponent,
        AvailableForDirective,
        ChartsComponent
    ],
    imports: [
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
      }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    breaks: true,
                },
            },
        }),

        CommonModule,
        ConfirmDialogModule,
        CarouselModule,
        DropdownModule,
        MultiSelectModule,
        InputTextModule,
        ButtonModule,
        TooltipModule,
        ToastModule,
        SidebarModule,
        RadioButtonModule,
        FormsModule,
        DialogModule,
        StyleClassModule,
        DynamicDialogModule,
        InputNumberModule,
    ],
    exports: [
        ConfirmationDialogComponent,
        ExcelTablesInfoComponent,
        SummaryDialogComponent,
        SelectDialogComponent,
        FeedbackDialogComponent,
        LoaderComponent,
        FileuploadMetadataDialogComponent,
        CodeDialogComponent,AvailableForDirective,ChartsComponent
    ],
    providers: [MessageService, ConfirmationService],
})
export class SharedModule {}
