import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export class BaseStateService<T> {
    private _initialState: T;

    public get initialState(): T {
        return cloneDeep(this._initialState);
    }

    public get value(): T {
        return cloneDeep(this._value$.getValue());
    }

    public get value$(): Observable<T> {
        return this._value$.asObservable()
            .pipe(
                map((value: T) => cloneDeep(value))
            );
    }

    public set value(value: T) {
        const clone = cloneDeep(value);
        this._value$.next(clone);
    }

    protected _value$: BehaviorSubject<T>;

    constructor() {
        this.setDefaultValue();
    }

    public init(value: T): void {
        this._initialState = cloneDeep(value);
        this.value = cloneDeep(value);
    }

    public reset(): void {
        this.value = cloneDeep(this._initialState);
    }

    protected setDefaultValue(): void {
        this._value$ = new BehaviorSubject<T>(null);
    }
}
